.App {
    text-align: center;
}

/* CUSTOM SCROLLBAR */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.wp-block-image > img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

figure {
    margin: 45px 0;
}

iframe {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 45px 0;
}

.wp-element-caption {
    font-weight: 600;
    font-size: 16px;
    margin-top: 15px;
}

.underline:hover {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: #212529 !important;
}

.clamp2 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.clamp3 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.clamp4 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
}

.clamp5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
}

.modal-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.parser > p {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
}

.post-card {
    width: 100%;
}

.post-card:hover .read-post {
    text-decoration: underline;
}
